@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;700&display=swap');

.react-datepicker {
  @apply rounded-lg border-none font-sans !important;
}

.react-datepicker__day{
  @apply text-text !important;
}

.react-datepicker__month-container {
  @apply rounded-lg bg-selectors  !important;
}

.react-datepicker__day-name {
  @apply text-text  !important ;
}

.react-datepicker__day:hover {
  @apply text-text bg-background !important;
}

.react-datepicker__day--today{
  @apply text-text rounded-md !important;
}

.react-datepicker__header {
  @apply border-text bg-selectors text-text !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  @apply border border-transparent bg-titles text-background  !important;
  &:hover {
    @apply bg-background text-text border border-titles !important;
  }
}

.react-datepicker__day--in-selecting-range {
  @apply bg-titles text-background !important;
  &:hover{
    @apply bg-background text-text border border-titles !important;
  }
}
