@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-selectors: 204, 224, 255;
    --color-popup: 217, 232, 255;
    --color-blocks: 188, 214, 255;
    --color-background: 233, 242, 255;
    --color-accent: 30, 2, 245;
    --color-text: 37, 37, 58;
    --color-labels: 68, 68, 87;
    --color-light-rose: 252, 212, 230;
    --color-titles: 3, 3, 28;

    --color-success: 100, 186, 155;
    --color-error: 234, 127, 127;
    --color-warning: 221, 180, 34;
    --color-information: 0, 163, 215;

    --reach-dialog: 1;
  }

  .darkMode {
    --color-selectors: 68, 68, 87;
    --color-popup: 52, 52, 73;
    --color-blocks: 37, 37, 58;
    --color-background: 3, 3, 28;
    --color-accent: 30, 2, 245;
    --color-text: 184, 220, 255;
    --color-labels: 233, 242, 255;
    --color-light-rose: 252, 212, 230;
    --color-titles: 255, 171, 201;

    --color-success: 189, 255, 212;
    --color-error: 255, 143, 143;
    --color-warning: 255, 253, 171;
    --color-information: 171, 243, 255;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-background) var(--color-popup);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--color-popup);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-background);
  border-radius: 14px;
  border: 3px solid var(--color-popup);
}
